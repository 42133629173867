@import '~antd/dist/antd.css';

$background-color: #fafafa;
$draft: gray;
$pending: black;
$submitted: blue;
$approved: green;
$rejected: darkred;

.App {

}

.ant-layout-footer, .ant-layout {
  background: #FFFFFF;
}

.table {
  margin-top: 20px;
}

.pagination button {
  border: none;
  background: none;
  cursor: pointer;
}

.version {
  margin-top: 8px;
  padding: 20px;
}

.actions {
  text-align: right;
  & > * {
    margin-left: 10px;
  }
}

.main-sider {
  min-height: 100vh;
}

.role {
  position: absolute !important;
  bottom: 60px;
  left: 25px;
}

.logout-button {
  position: absolute !important;
  bottom: 0;
}

.dashboard-page {
  .ant-statistic {
    text-align: center;
    margin: 6%;
  }
}

.login-page, .register-page{
  background-color: $background-color;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .ant-card-body{
    width: 370px;
  }

  .actions{

  }
  h2{
    text-align: center;
  }

  small{
    text-align: center;
    margin-bottom: 10px;
  }
}

.create-cta{
  margin-bottom: 20px;
}

.upload-image-modal{
  max-width: 200px;
  display: block;
  margin-bottom: 13px;
}

.pet-row img{
  max-width: 200px;
}

.pet-detail, .detail-page{
  .ant-image-img{
    max-width: 300px;
  }
}

.user-row{
  img{
    max-height: 80px;
  }
}

.profile-icon-image{
  max-width: 200px;
}

.kid-row, .carer-row{
  .kid-id, .kid-name-row, .carer-id, .carer-name-row{
    color: #1890ff;
    cursor: pointer;
  }
}

.usps-container{
  &>button{
    margin-bottom: 10px;
  }
  .usps-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .usp{
      margin: 2%;
      margin-bottom: 10px;
      width: 45%;

      .draft .ant-card-head-title{
        color:$draft;
      }
      .pending .ant-card-head-title{
        color:$pending;
      }
      .submitted .ant-card-head-title{
        color:$submitted;
      }
      .approved .ant-card-head-title{
        color:$approved;
      }
      .rejected .ant-card-head-title{
        color:$rejected;
      }

      .ant-card-head-title{
        color: #1890ff;
      }
    }
  }
}

.save-comment{
  margin-top: 5px;
  float:right;
}

.delete-comment{
  font-size: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -9px;
  right: 0;
}

.ticket-desc{
  padding: 20px 15px;
}

.ticket-comments{
  padding: 0px 15px;
}

.ticket-status{
  text-transform: capitalize;
}

.ticket-status-dropdown{
  width: 100%;
  min-width: 210px;
}

.hotel-brand{
  text-transform: capitalize;
}

.setpass {
  .ant-form-item-explain {
    padding: 5px 0;
    text-align: left;
    font-size: 13px;
  }
}